import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoutEmployee = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('authToken'); // Check for auth token

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default PrivateRoutEmployee;
